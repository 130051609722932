exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-full-screen-json-js": () => import("./../../../src/pages/full-screen-json.js" /* webpackChunkName: "component---src-pages-full-screen-json-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-js": () => import("./../../../src/pages/private.js" /* webpackChunkName: "component---src-pages-private-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-json-diff-viewer-js": () => import("./../../../src/templates/json-diff-viewer.js" /* webpackChunkName: "component---src-templates-json-diff-viewer-js" */),
  "component---src-templates-jsoneditor-jsoneditor-js": () => import("./../../../src/templates/jsoneditor-jsoneditor.js" /* webpackChunkName: "component---src-templates-jsoneditor-jsoneditor-js" */),
  "component---src-templates-monaco-editor-js": () => import("./../../../src/templates/monaco-editor.js" /* webpackChunkName: "component---src-templates-monaco-editor-js" */)
}

